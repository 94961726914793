const en = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "You can set your targeting strategy for your campaign.",
    campaign_form_budget_text:
      "Select the budget type of the campaign and set the budget. Once your spend reaches the budget, the campaign will automatically stop.",
    campaign_form_creative_text:
      "You can upload the creatives you want to use in your campaign.",
    invoice_modal_help_text:
      "If you continue by clicking the Choose a payment method button, you are deemed to have confirmed that the amount to be collected will not be refunded.",
    invoice_general_error_modal_title: "Failed to Load Balance!",
    invoice_general_error_modal_text: "An error has occurred.",
    invoice_unknown_error_modal_text: "An unexpected error occurred.",
    invoice_product_id_error_modal_text: "The product id field is required.",
    invoice_signature_error_modal_text: "Signature verification failed.",
    invoice_transaction_error_modal_text:
      "The transaction id has already been taken.",
    invoice_store_error_modal_text:
      "The store does not belong to the given company.",
    invoice_balance_error_modal_text: "Store doesn't have enough balance.",
    invoice_error_modal_title: "Failed to Load Balance!",
    invoice_error_modal_text: "An error has occurred.",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "Campaigns",
      report: "Reports",
      campaigns: "Campaigns",
      reports: "Reports",
      invoices: "Invoices",
      academy: "Academy",
      chat: "Support via E-mail",
    },
    admin: {
      campaign: "Campaigns",
      advertisers: "Advertisers",
      agencies: "Agencies",
      invoices: "Invoices",
      finance: "Financial",
      transactions: "Transactions",
      coupons: "Coupons",
      access: "Access",
      users: "Users",
      invited: "Invited",
      synchronization: "Synchronization History",
      activity: "Activity History",
      report: "Reports",
      settings: "Settings",
      general: "General Information",
      integration: "Integration Information",
      advertiser: "Advertiser Settings",
      auction: "Auction Settings",
      single: "Campaign Settings",
      campaigns: "Campaigns",
      reports: "Reports",
      admin_report: "Reports",
      ad: "Ad",
      placement: "Ad Placement",
      creatives: "Creatives",
      review: "Ad Review",
      restrictions: "Restriction Settings",
    },
  },
  components: {
    adminBar: {
      rn: "You are currently viewing",
      visible: "advertiser screen.",
      back: "Back to Admin View",
    },
    advertiserSelect: {
      placeholder: "Select An Advertiser",
    },
    articles: {
      date: "When Does Your Campaign Start and End?",
      budget: "What is a Budget? What to Consider When Choosing a Budget?",
      bid: "What is bidding and what are bid types?",
      targeting: "Targeting types and how to target correct audience?",
      frequency: "What is delivery type in reporting?",
      type: "How to create a custom report?",
      product: "What should be considered while choosing a product?",
      store: "What should be considered while choosing a store?",
    },
    breadcrumb: {
      finance: "Transactions",
      settings: "General Information",
      access: "Users",
    },
    bulkUpdate: {
      list: "List Selected",
      archive: "Archive Selected",
      pause: "Pause Selected",
      activate: "Activate Selected",
      selected: "{{value}} units selected",
      bid: "Edit Selected Bids",
      bidLabel: "Bid",
      bidHelp: "The bid of the selected rows will be updated.",
      row: "{{value}} Row",
      count: "Selected:",
      updateAllBids: "Edit Bids",
      updateAllBidsText: "You can edit bids for all rows shown in the table.",
      updatingAllBids: "You are editing the bids of all row.",
      updateAllBidsHelp:
        "The bids for all rows displayed in the table will be updated.",
    },
    download: {
      campaigns: "Campaigns",
      advertisers: "Advertisers",
      agencies: "Agencies",
      transactions: "Transactions",
      coupons: "Coupons",
      users: "Users",
      sync_logs: "Sync Logs",
      reports: "Reports",
      query: "Performance Report",
      PRODUCTS: "Products",
      KEYWORDS: "Keywords",
      NEGATIVE_KEYWORDS: "Negative Keywords",
      AGE_RANGES: "Age",
      CITIES: "City",
      PLATFORMS: "Platform",
      GENDERS: "Gender",
      PLACEMENTS: "Ad Placement",
      CUSTOMERS: "Customer",
      CATEGORIES: "Categories",
      NEGATIVE_CATEGORIES: "Negative Categories",
      CREATIVES: "Creatives",
    },
    info: {
      firstTipCreate:
        "You can easily create successful campaigns by choosing the recommended settings. Recommended settings optimize your ad performance for you.",
      secondTipCreate:
        "In order to increase performance of your campaigns you can select products with sufficient stock, high scores and buybox winners to your campaigns.",
      thirdTipCreate:
        "Increasing advertising budget help you to boost your clicks and sales by making your products more visible to your customers.",
      forthTipCreate:
        "By placing high bids on your campaigns, you can ensure that your products are displayed in better position.",
    },
    invite: {
      selected: "{{value}} units selected",
      invite: "Invite Selected",
      reinvite: "Re-Invite Selected",
    },
    modal: {
      campaign: {
        create: "Create New Campaign",
        success: "Campaign Created Successfully",
        successText:
          "You can continue to the campaign details page by clicking the 'OK' button.",
      },
      campaignDetails: {
        products: "Product Selection",
        keywords: "Add Keyword",
        update: "Update Campaign",
        negative_keywords: "Add Negative Keyword",
        products_btn: "Add Products",
        keywords_btn: "Add Keywords",
        negative_keywords_btn: "Add Negative Keywords",
        update_btn: "Update",
        create_agency: "Add New Agency",
        update_agency: "Update Agency",
        create_agency_btn: "Add",
        update_agency_btn: "Update",
        create_user: "Add New User",
        create_user_marketplace: "Add Admin User",
        create_user_marketplace_btn: "Add",
        create_user_advertiser: "Add Advertiser User",
        create_user_advertiser_btn: "Add",
        select_advertiser: "Select Advertiser",
        select_advertiser_btn: "Continue",
        update_advertiser: "Update Advertiser",
        update_advertiser_btn: "Update",
        invoice: "Add Balance",
        invoice_btn: "Choose Payment Method",
        coupons: "Define Coupon",
        coupons_btn: "Define Coupon",
        transactions: "Add Balance",
        transactions_btn: "Add Balance",
        categories: "Add New Category",
        categories_btn: "Add Categories",
        negative_categories: "Add Negative Category",
        negative_categories_btn: "Add Categories",
        transaction_details: "Transaction Details",
        settings: "Save Changes",
        settings_btn: "Save",
        creatives: "Add Creative",
        creatives_btn: "Add Creative",
        approve_balance: "Add Balance",
        approve_balance_btn: "Confirm Transaction",
        approve_coupon: "Add Coupon",
        approve_coupon_btn: "Confirm Coupon",
        payment_options: "Add Balance",
        payment_options_btn: "Load",
        add_user: "Invite New User",
        add_user_btn: "Send invitation",
        targeting_settings: "Advertiser Selection",
        targeting_settings_btn: "Select",
        update_transaction: "Edit Invoice Number",
        update_transaction_btn: "Save",
        select_user: "Select User Type",
        select_user_btn: "Continue",
        select_language: "Change Language",
        select_language_btn: "Change",
        campaign_form: "Campaign Summary",
        campaign_form_btn: "Create Campaign",
        update_bid: "Edit Bid",
        update_bid_btn: "Edit",
        show_vast_tag: "Get VAST Tag",
        show_vast_tag_btn: "Okay",
        update_dayparting: "Day Parting",
        update_dayparting_btn: "Update",
        reject_creative: "Reject Creative",
        reject_creative_btn: "Confirm",
      },
      selectCampaign: {
        title: "Choose Your Campaign Type",
        text: "Please select your campaign type.",
        soon: "Soon",
        sponsored_product_title: "Sponsored Products",
        sponsored_product_text:
          "Promote to shoppers that search for related keywords or view similar products.",
        sponsored_brand_title: "Sponsored Brands",
        sponsored_brand_text:
          "Help shoppers discover your brand and products with rich, engaging creatives.",
        sponsored_display_title: "Sponsored Display",
        sponsored_display_text:
          "Grow your business by reaching relevant audiences on and off site.",
        sponsored_store_title: "Sponsored Store",
        sponsored_store_text:
          "Amplify your store’s reach and help shoppers discover your stores. Deliver a targeted shopping experience.",
        sponsored_video_title: "Sponsored Video",
        sponsored_video_text:
          "Reach your target audience and increase sales using engaging elements with video ads.",
        link: "What is a Display Ad?",
      },
      success: {
        advertisers_success_title: "Invitation Sent",
        advertisers_success_text:
          "An invitation has been sent to the email address you entered. Users need to create an account by clicking the invitation link in order to access their accounts.",
        reports_success_title: "Report Created Successfully",
        reports_success_text:
          "You can continue to the report detail page by clicking the “OK” button.",
        invited_success_title: "User Invited",
        invited_success_text:
          "In order for the user to appear in the list, they must click on the invitation e-mail and create a password.",
        agencies_success_title: "Agency Created Successfully",
        agencies_success_text: "",
        multiple_advertisers_success_title: "Invitation Sent",
        multiple_advertisers_success_text: "Selected advertisers were invited.",
        invoice_success_title: "Balance Load Successful!",
        invoice_success_text:
          "Your balance has been loaded. You can create new campaigns.",
        create_advertiser_success_title: "Add Advertiser Successful!",
        create_advertiser_success_text:
          "You need to invite the advertiser to activate it.",
        payment_success_title:
          "Your Balance Loading Request Has Been Received!",
        payment_success_text:
          "After your money transfer or EFT payments are approved, they will be transferred to your account. The approval process can take 2-3 business days.",
        craftgate_success_title: "Your Balance Loading Request Received!",
        craftgate_success_text:
          "Your balance will be transferred to your account promptly.",
        onboarding_success_title: "Congratulations!",
        onboarding_success_text:
          "You’ve successfully completed the onboarding process and now have full access to all features of the dashboard! Explore the new interface, create impactful campaigns, and manage your ads effortlessly. Click “Ok” to get started!",
      },
      error: {
        craftgate_title: "The Balance Could Not Be Loaded!",
        craftgate_text: "Credit card loading failed.",
      },
      warning: {
        campaign_warning_title: "Are you sure you want to leave this page?",
        campaign_warning_text: "Your changes will not be saved!",
      },
    },
    navbar: {
      search: "Type to search...",
      help: "Help & Support",
      site: "Return to Seller Panel",
    },
    navbarPopover: {
      account: "Change Account",
      language: "Change Language",
      logout: "Logout",
    },
    notification: {
      formTitle: "The form is not complete!",
      formText: "Please fill in the blanks and required fields!",
      paused: "Stopping {{value}} was successful.",
      active: "Activation of {{value}} was successful.",
      campaigns: "the campaign",
      reports: "the report",
      products: "the product",
      keywords: "the keyword",
      negative_keywords: "the negative keyword",
      categories: "the category",
      negative_categories: "the negative category",
      placements: "the ad placement",
      users: "the user",
      advertisers: "the advertiser",
      agencies: "the agency",
      ad_placements: "the ad placement",
      creatives: "the creative",
      account_users: "the user",
      statusError: "An error has occurred.",
      activeReport: "Archiving the report was successful.",
      archivedReport: "Unarchiving the report was successful.",
      activeCampaign: "Archiving the campaign was successful.",
      pausedCampaign: "Archiving the campaign was successful.",
      archivedCampaign: "Unarchiving the campaign was successful.",
      activeAgency: "Archiving the agency was successful.",
      archivedAgency: "Unarchiving the agency was successful.",
      invitedAdvertiser: "Archiving the advertiser was successful.",
      activeAdvertiser: "Unarchiving the advertiser was successful.",
      archivedAdvertiser: "Archiving the advertiser was successful.",
      archived_reports_bulk: "Archiving the report was successful.",
      archived_campaigns_bulk: "Archiving the campaign was successful.",
      archived_users_bulk: "Archiving the user was successful.",
      archived_advertisers_bulk: "Archiving the advertiser was successful.",
      archived_agencies_bulk: "Archiving the agency was successful.",
      archived_account_users_bulk: "Archiving the user was successful.",
      keyword: "Please add at least 1 keyword.",
      category: "Please add at least 1 category.",
      invite: "{{value}} invitation(s) sent successfully.",
      full_invite: "All invitations sent successfully.",
      rejected: "Creative rejection successfully completed.",
      accepted: "Creative validation successfully completed.",
      uninvitedAdvertiser: "Activation the advertiser was successful.",
      settings_success: "Settings saved successfully.",
      previous_invite_sent_too_recently:
        "{{value}} invite(s) sent too recently. Wait to try again.",
      user_email_is_already_registered:
        "{{value}} email address(es) is/are already registered.",
      copiedCampaign: "The campaign was successfully copied.",
      copyError: "An error occurred while copying the campaign.",
      editableFieldError:
        "Please enter a value greater than the minimum, or it will be adjusted accordingly.",
      active_account_user: "Archiving the user was successful.",
      archived_account_user: "Unarchiving the user was successful.",
      bid: "Bids of {{value}} rows has been updated.",
      newBid: "New bid: {{value}}.",
      creativeUpload: "Creative added successfully!",
    },
    suggestedTag: {
      text: "RECOMMENDED",
    },
    campaignCard: {
      graph: "Show in Graph",
      hide: "Hide",
      message: "Last 30 Days",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "Keyword Information",
        categoryStepTitle: "Category Information",
        targetingStepTitle: "Audience Information",
        campaignInfoTitle: "Campaign Information",
        campaignInfoText:
          "Enter a name to be displayed in the campaigns table. This name is not shown to customers. We recommend you to choose a name related to the products you want to advertise.",
        campaignLabel: "Campaign Name",
        campaignSub: "Enter your campaign name.",
        dateInfoTitle: "Campaign Date",
        dateInfoText: "Set the duration of your campaign.",
        startDateLabel: "Start Date",
        startDateSub: "Select the campaign start date.",
        stopped: "Continuous",
        stoppedText:
          "Your campaign will start today and continue until you stop it.",
        stoppedSub: "Start Date: {{value}}",
        defined: "Start - End Date",
        definedText:
          "You can select the date range in which your campaign will run.",
        budget: "Budget",
        totalBudget: "Total Budget Limit",
        totalBudgetLabel: "Total Budget",
        dailyBudgetLabel: "Daily Budget",
        dailyBudgetExtra:
          "The daily expenditure of your campaign will be the maximum amount you specify.",
        dailyBudgetFilter: "Daily Budget",
        dailyBudgetText: "You can set your daily budget.",
        totalBudgetExtra:
          "Your campaign will spend the maximum amount you specify every day.",
        flexibleBudgetLabel: "Daily Budget",
        totalBudgetFilter: "Total Budget",
        totalBudgetText: "You can set your total budget.",
        bid: "Bid",
        bidText:
          "Set the bid type for your campaign. Your ad spend is per click based on the bid type you choose.",
        dynamicBid: "Automatic Bid",
        dynamicBidText:
          "Your spend per click will be determined automatically.",
        targetAcosTitle: "Target ACoS",
        targetAcosText:
          "Choose how much of your sales coming from ads you want to spend on advertising.",
        default: "Default",
        advanced: "Advanced",
        fixedBid: "Fixed Bid",
        fixedBidText:
          "Set a fixed cost per click for keywords and categories. Your campaign is shown to customers based on your targeting preference.",
        productTitle: "Product Selection",
        productText: "Select the products you want to advertise.",
        acosTooltip:
          "ACoS stands for the advertising cost of a sale. It is the ratio that shows how much of your earnings from sales you want to spend on advertising. For example, in a campaign with 10% ACoS value, you spend 1 TL on advertising for every 10 TL sales.",
        autoTargeting: "Auto Targeting",
        autoTargetingText:
          "The most appropriate keywords or categories for your campaign will be targeted.",
        manualTargeting: "Manual Targeting",
        manualTargetingText:
          "You can target the keywords or categories you want your campaign to display.",
        suggestedBidText: "Suggested Bid: ",
        acosError: "ACoS should be between 0.0001-100.",
        creativeTitle: "Creative Information",
        videoText:
          "You can upload the creatives you want to use in your campaign.",
        urlTitle: "Redirect URL",
        urlText:
          "It is the page where users who click on your ad will be directed. You need to redirect users to a page within the marketplace.",
        draggerTitle: "Upload .PNG, .JPG file to upload creative",
        draggerText: "or drag and drop here",
        creativeLabel: "Creative Name",
        size: "Size:",
        listWarningSize: "The maximum file size for any creative is 5120 KB.",
        listWarningType: ".jpg .png are accepted.",
        listWarningRecommended:
          "You can review the creative dimensions for ad placements and accepted creative sizes.",
        modalTitle: "Creative Dimensions",
        modalText:
          "You can upload creative in all listed sizes. Uploading creative at recommended sizes improves your ad performance.",
        recommended: "Recommended Size",
        accepted: "Accepted Sizes",
        creativeError: "Please enter a creative name.",
        urlError: "Please enter a URL.",
        budgetError: "Your budget must be over {{value}}.",
        budgetErrorMessage: "Please enter your budget.",
        invalid: "INVALID SIZE",
        advancedOptions: "Advanced Options",
        placements: "Ad Placement",
        placementsText:
          "Decide in which areas you want your ad to be displayed.",
        in_search: "Search Results",
        in_search_text:
          "Your ads are displayed in the relevant search results.",
        in_product_detail_page: "Product Detail Pages",
        in_product_detail_page_text:
          "Your ads are displayed on the relevant product detail pages.",
        in_category: "Categories",
        in_category_text:
          "Your ads are displayed on the relevant category pages.",
        in_home_page: "Home page",
        in_home_page_text: "Your ads are displayed on the homepage.",
        in_collection: "Collection",
        in_collection_text:
          "Your ads are displayed on relevant collection pages.",
        storeTitle: "Store Selection",
        storeText: "Select the stores you want to advertise.",
        info: "GENERAL INFORMATION",
        advertiser: "Advertiser Name:",
        budgetType: "Budget Type:",
        bidType: "Bid Type:",
        standart: "Automatic Bid",
        fixed: "Fixed Bid",
        max_acos: "Target ACoS",
        fixed_info: "Bid:",
        max_acos_info: "ACoS:",
        targeting: "TARGETING",
        custom: "Custom",
        durationText:
          "If your campaign reaches the daily budget target every day, it will stay active for <strong>{{value}} days</strong>.",
        dayParting: "Day Parting",
        dayPartingText:
          "Determine the days and hours that your campaign will remain active.",
        allTimes: "All Times",
        allTimesText: "Your campaign is active all day and at all hours.",
        scheduled: "Select Date Range",
        scheduledText:
          "Your campaign is active on the days and hours you specify.",
        dayPartingTableText:
          "Please tick the appropriate <strong>boxes</strong> below to select the day and time interval you want. If you want to select all time intervals during a day, you can use the <strong>'All Day'</strong> option.",
        allDay: "All Day",
        clear: "Clear Selections",
        videoSideTitle: "Add Creative",
        videoSideText: "Select creative types and determine their formats.",
        firstDrawerTitle: "Step 1: Select Creative Type",
        creativeTypeTitle: "Creative Type",
        creativeTypeText: "Select the creative type suitable for your ad.",
        mp4Title: "Create via Video File",
        mp4Text: "You can create your creative with a .MP4 video file.",
        vastTitle: "Create using a VAST Tag",
        vastText: "You can create your creative with the data-backed VAST Tag.",
        secondDrawerTitle: "Step 2: Add via Video File",
        creativeNameTitle: "Creative Name",
        creativeNameText: "Enter a name for your creative.",
        creativeVideoLabel: "Enter creative name.",
        upload: "Upload Video",
        uploadDrawer: "Upload the video file to show in your ads.",
        videoDraggerTitle: "Select a <strong>.MP4</strong> file to upload",
        videoDraggerText: "or drag and drop here.",
        maxVideoSize: "The maximum file size for any creative is 10MB.",
        videoFormat: "Only .mp4 files are accepted.",
        changeVideo: "Change Video",
        creativeName: "<strong>Creative Name:</strong> {{value}}",
        updateDrawerTitle: "Update Creative",
        videoAd: "Sponsored Video",
        vast: "VAST Tag",
        vastDrawer:
          "Add the VAST tag for the video that will appear in your ads.",
        videoInnerTitle: "We're processing your video!",
        videoInnerText:
          "Once the system has finished processing, your video will be ready to view!",
        errorStates: {
          name: "Please enter the campaign name.",
          keywordErr: "Your keyword must be unique.",
          advertiserErr: "Advertiser is not active.",
          placementsError: "Please select at least one ad placement.",
          productLimitErr: "You can add up to 250 products.",
          sameKeyword:
            "Cannot include same keyword in target keywords and negative keywords.",
          duplicateKeyword: "Keyword already added to campaign",
          duplicateNegativeKeyword:
            "Negative keyword already added to campaign",
          generalErr: "An error has occurred.",
          campaignLimitErr:
            "You have reached the campaign limit for SMO marketplaces.",
          creativeLimitErr:
            "The creative limit for SMO marketplaces is 100, and this limit has been reached.",
          creativeSizeErr:
            "The file size exceeds the limit for SMO marketplaces. Please upload a file smaller than 150 KB.",
          vast: "Please enter a valid VAST tag.",
          keywordLimitErr: "At most 100 keywords can be added to a campaign.",
          categoryLimitErr: "At most 100 categories can be added to a campaign",
        },
      },
      secondStep: {
        wordTargeting: "Keyword Targeting",
        wordTargetingText:
          "Determine in which keywords your ads will be displayed.",
        categorySub:
          "Decide in which categories you want your ad to be displayed.",
        autoTargeting: "Auto Targeting",
        autoTargetingText:
          "Your ad will be displayed in the most relevant keywords and categories. You can stop the keywords from the campaign detail page.",
        manualTargeting: "Manual Targeting",
        manualTargetingText: "You can set keywords for your ad.",
        defaultBid: "Default Bid",
        keywordTitle: "Target Keywords",
        keywordText: "Add the keywords which your ad will be displayed.",
        negativeTitle: "Negative Keywords",
        negativeText:
          "Add the keywords that you do not want your ad to be displayed.",
        placementTitle: "Targeting Type",
        placementText:
          "You can target specific keywords or categories in which your campaign will be displayed.",
        search: "Keyword Targeting",
        searchText:
          "Target the keywords that you want your campaign to appear in.",
        categories: "Category Targeting",
        categoriesText:
          "Target the categories that you want your campaign to display on.",
        categoryTargeting: " Targeting",
        categoryTargetingTitle:
          "Determine in which categories your ads will be displayed.",
        categoryTargetingText:
          "Add the categories which your ads will be displayed.",
        category: "Target Categories",
        negativeCategoryTitle: "Negative Categories",
        negativeCategoryText:
          "Add the categories that you do not want your ad to be displayed.",
        autoCategoryText:
          "Your ad will be displayed in the most appropriate categories.",
        manualCategoryText:
          "You can set the categories you target for your ad.",
        placement: "Targeting",
        targetingText: "You can set your targeting strategy for your campaign.",
      },
      thirdStep: {
        targeting: "Audience Selection",
        targetingText:
          "Determine which audiences you want your ad to be displayed.",
        autoTargeting: "Automatic Targeting",
        autoTargetingText:
          "Your ad will be displayed in the most relevant keywords and categories.",
        manualTargeting: "Manual Targeting",
        manualTargetingText:
          "You can determine which audiences your products will be displayed.",
        platform: "Platform Targeting",
        platformText:
          "Determine which platform users you want your ad to be displayed.",
        tablet: "Tablet",
        mobile: "Mobile",
        app: "Mobile Application",
        desktop: "Desktop",
        age: "Age Range Targeting",
        ageText: "Determine which age group you want your ad to be displayed.",
        city: "City Targeting",
        cityText: "Determine which cities you want your ad to be displayed.",
        allCities: "All Cities",
        selectedCities: "Selected Cities",
        cityPlaceholder: "Select City",
        customer: "Customer Targeting",
        customerText:
          "Determine which customers you want your ad to be displayed based on their shopping history.",
        presentCustomers: "Existing Customers",
        presentCustomersText:
          "Target customers who have already ordered the products you advertise.",
        newCustomers: "New Customers",
        newCustomersText:
          "Target customers who have never ordered the products you advertise",
        gender: "Gender Targeting",
        genderText: "Determine which gender you want your ad to be displayed.",
        female: "Female",
        male: "Male",
      },
    },
    columnPopover: {
      title: "Edit Columns",
    },
    empty: {
      product_title: "Please make a product selection.",
      store_title: "Please make a store selection.",
      dimensions_title: "You haven't added dimension yet.",
      product_text:
        "You can add the products you want to advertise by clicking the add button in the product list on the left.",
      store_text: "You can add the stores you want to advertise.",
      dimensions_text:
        "You can add dimensions to customize your campaign results' level of detail.",
      null_title: "No Data",
      null_text: "There is no data in the table.",
      categories_title: "Please add the categories you will target.",
      categories_text:
        "Specify the categories you want your ad to appear in by entering a category or clicking the add button in the suggested categories table.",
      keywords_title: "Please add the keywords you will target.",
      keywords_text:
        "Specify the keywords you want your ad to show by entering a word or clicking the add button in the suggested keywords table.",
      negative_keywords_title: "You haven't added any negative keywords yet.",
      negative_keywords_text:
        "You can specify negative keywords that you do not want your ad to show by entering a word.",
      negative_categories_title:
        "You have not added any negative categories yet.",
      negative_categories_text:
        "You can specify the negative categories that you do not want your ad to show.",
      campaigns_table_title: "You Haven't Created Any Campaign",
      campaigns_table_text:
        "You can create your first campaign by clicking the Add New Campaign button.",
      reports_table_title: "You Haven't Created Any Report",
      reports_table_text:
        "You can create your first report by clicking the Add New Report button.",
      invoices_table_title: "You Haven't Loaded Any Balance",
      invoices_table_text:
        "You can add an ad balance by contacting your account manager.",
      advertisers_table_title: "There is no advertiser linked to your account",
      advertisers_table_text: "Please contact your account manager.",
      agencies_table_title: "You Haven't Created An Agency",
      agencies_table_text:
        "You can create an agency by clicking the Add New Agency button.",
      transactions_table_title: "You Haven't Loaded Any Balance",
      transactions_table_text:
        "You can add ad balance to your list of advertisers by clicking the Load Balance button.",
      coupons_table_title: "You Haven't Created Any Coupons",
      coupons_table_text:
        "You can define coupons for your advertisers by clicking the Define Coupon button.",
      account_users_table_title: "You Haven't Created A User",
      account_users_table_text:
        "You can create a user by clicking the Add new user button under the Invited tab.",
      archived_table_reports_title: "No Archived Reports",
      archived_table_campaigns_title: "No Archived Campaigns",
      archived_table_agencies_title: "No Archived Agencies",
      archived_table_account_users_title: "No Archived Users",
      archived_table_advertisers_title: "No Archived Advertisers",
      invited_table_users_title: "No Invited Users",
      searched_table_title: "No records were found matching your search.",
      searched_table_text: "",
      searched_title: "No records were found matching your search.",
      searched_text: "",
      products_table_title: "You haven’t added any products yet.",
      products_table_text:
        "You can add the products you want to advertise by clicking the add products button.",
      keywords_table_title: "You haven't added any keywords yet.",
      keywords_table_text:
        "You can specify keywords that you want your ad to show by entering a word.",
      categories_table_title: "You haven't added any categories yet.",
      categories_table_text:
        "You can specify categories that you want your ad to show by entering a word.",
      negative_keywords_table_title:
        "You haven't added any negative keywords yet.",
      negative_keywords_table_text:
        "You can specify negative keywords that you do not want your ad to show by entering a word.",
      negative_categories_table_title:
        "You haven't added any negative categories yet.",
      negative_categories_table_text:
        "You can specify negative categories that you do not want your ad to show.",
      sync_logs_table_title: "No Sync History Data",
      sync_logs_table_text: "No sync history data found.",
      ad_placements_table_title: "You haven't added any ad placements yet.",
      ad_placements_table_text:
        "You can add ad placements by clicking the add ad placement button.",
      creatives_table_title: "No creatives have been added yet!",
      creatives_table_text: "Uploaded creatives will appear here.",
      creatives_relations_table_title: "You haven't created a creative yet.",
      creatives_relations_table_text:
        "You can create new creative from the campaign form.",
      creativeForm_title: "You haven't added any creatives yet.",
      creativeForm_text: "Suggested creative sizes:",
      invites_table_title: "You Haven’t Invited a User",
      invites_table_text:
        "You can invite users by clicking the Add New User button.",
      review_title: "There are currently no creatives to review.",
      review_text:
        "All creatives have been approved, or no creatives have been uploaded. Creatives pending approval will appear on this screen.",
      accepted_table_creatives_title: "No creatives approved yet!",
      accepted_table_creatives_text:
        "All creatives have been rejected, or no creatives have been uploaded.",
      rejected_table_creatives_title: "No creatives rejected yet!",
      rejected_table_creatives_text:
        "All creatives have been approved, or no creatives have been uploaded.",
      pending_table_creatives_title: "No creatives added yet!",
      pending_table_creatives_text:
        "All creatives have been reviewed, or no creatives have been uploaded.",
      blacklist_table_title: "No keyword restriction found.",
      blacklist_table_text:
        "You can add a restriction by clicking the Add New Keyword button.",
      cities_table_title: "You haven't added any cities yet.",
      cities_table_text: "",
      age_ranges_table_title: "You haven't added any age ranges yet.",
      age_ranges_table_text: "",
      genders_table_title: "You haven't added any genders yet.",
      genders_table_text: "",
      platforms_table_title: "You haven't added any platforms yet.",
      platforms_table_text: "",
      customers_table_title: "You haven't added any customers yet.",
      customers_table_text: "",
      report_loading_title: "Report Generation in Progress",
      report_loading_text:
        "Your report is currently being created. This process may take some time depending on the complexity and size of the report.",
    },
    endDatePicker: {
      label: "End Date",
      campaigns: "Select the campaign end date.",
      reports: "Select the report end date.",
      button: "No End Date",
    },
    reportForm: {
      title: "Report Information",
      nameTitle: "Report name",
      nameText: "Enter a name to be displayed in the reports table.",
      nameSub: "Enter your report name.",
      reportTypeTitle: "Delivery",
      reportTypeText:
        "Select the delivery type of your report. Reports that you have generated will be delivered according to your preferences.",
      static: "Fixed",
      staticText: "Report runs once and displayed in reporting.",
      scheduled: "Scheduled",
      scheduledText: "Select frequency and receivers of your report.",
      frequency: "Choose the frequency of your report.",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monhtly",
      sendDate: "Choose the date you want to receive your first report.",
      firstDay: "First Day of the Month",
      lastDay: "Last Day of the Month",
      exportType: "Choose in which format you want to receive your report.",
      mail: "Enter the e-mail addresses to which your report will be sent.",
      dateTitle: "Report Date",
      dateText: "Select the start and end date of your report.",
      startDateSub: "Select the report start date.",
      reportKindTitle: "Report Type",
      reportKindText:
        "You can easily measure performance with the predefined reports. With the customized report, you can create customized reports by adding dimensions, metrics and filters.",
      predefined: "Predefined Report",
      predefinedPlaceholder: "Select Predefined Report Type",
      special: "Customized Report",
      breaks: "Dimensions",
      breaksText: "Select the dimensions you want to include in your report.",
      breaksPlaceholder: "Search dimensions",
      maxWarning: "*You can add up to 3 dimensions to your report.",
      clear: "CLEAR",
      metrics: "Metrics",
      metricsText: "Select the metrics you want to include in your report.",
      filters: "Filters:",
      filtersText:
        "Select the filters you want to apply to the dimensions and metrics.",
      product: "Product Performance",
      product_category: "Category Performance",
      product_brand: "Brand Performance",
      keyword: "Keyword Performance",
      campaign: "Campaign Performance",
      advertiser: "Advertiser Performance",
      agency: "Agency Performance",
      errorStates: {
        date: "Please select a date.",
        mail: "Please enter an email.",
        type: "Please select report type.",
        metric: "Please select at least one metric.",
      },
      dimensions: {
        campaignName: "Campaign Name",
        campaignBidType: "Bid Type",
        campaignBudgetType: "Budget Type",
        product: "Product",
        categoryName: "Category Pages",
        keywordText: "Keyword",
        productBrand: "Product Brand",
        productName: "Product",
        day: "Day",
        week: "Week",
        month: "Month",
        year: "Year",
        hour: "Hour",
        advertiser: "Advertiser ID",
        advertiserName: "Advertiser",
        agency: "Agency ID",
        agencyName: "Agency",
        customerDeviceType: "Customer Device Type",
        saleProductName: "Indirect Sale Product",
        placementId: "Placement ID",
        customerType: "Customer Type",
        productRetailerTaxonomy: "Product Category",
        adPlacementCustomKey: "Ad Placement ID",
        adPlacementName: "Ad Placement Name",
        placementText: "Placement Text",
        in_search: "Search Results",
        in_category: "Category Pages",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Homepage",
        campaignAdType: "Campaign Type",
        campaignBudget: "Budget",
        campaignFixedBid: "Campaign Fixed Bid",
        keywordBid: "Keyword Bid",
        categoryBid: "Category Bid",
        advertiserResourceID: "Seller ID",
        productSKU: "Product ID",
        customerDeviceEnvironmentType: "Customer Device Environment Type",
        campaignDailyBudget: "Daily Budget",
        campaignTotalBudget: "Total Budget",
      },
      metric: {
        sumImpression: "Impressions",
        sumViewableImpression: "Viewable Impression",
        sumClick: "Clicks",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "Sale",
        ACoS: "ACoS",
        sumDirectSale: "Direct Sale",
        sumIndirectSale: "Indirect Sale",
        sumDirectSaleAmount: "Direct Sale Amount",
        sumIndirectSaleAmount: "Indirect Sale Amount",
        sumSaleAmount: "Sale Amount",
        sumSpend: "Spend",
        sale: "Sale",
      },
    },
    selectFilter: {
      title: "Filter Options",
      placeholder: "Select Filter",
      budgetOpt: "Remaining Budget",
      budgetAdvertiser: "Advertiser Name",
      placeholderCondition: "Choose Condition",
      bigger: "is greater",
      smaller: "is smaller",
      equal: "Equals",
      notEqual: "Not Equal",
      any: "contains",
      add: "Add Filter",
      placeholderValue: "Select Value",
      enter: "Enter Value",
      date: "Select Date",
      reportsText: "Select the filter you want to apply to your report.",
    },
    searchBar: {
      keywords: "Add keywords...",
      negative_keywords: "Add keywords...",
      categories: "Add categories...",
      negative_categories: "Add categories...",
    },
    settingsHeader: {
      general: "General Information",
      generalText: "General information about the marketplace",
      integration: "Integration Information",
      integrationText:
        "Integration information required to perform data transfer",
      advertiser: "Advertiser Settings",
      advertiserText: "Settings related to advertisers.",
      auction: "Auction Settings",
      auctionText: "Auction related settings",
      campaign: "Campaign Settings",
      campaignText: "Settings related to campaign setup",
      bid: "Bid Restrictions",
      bidText: "Settings for platform-wide bidding rules",
      conversion: "Conversion",
      conversionText:
        "Settings related to your conversion goals on the platform",
      category: "Category Restrictions",
      categoryText: "Restrictions on categories displayed on your platform",
      keyword: "Keyword Restrictions",
      keywordText: "Restrictions on keywords searched on your platform",
    },
    stepsNavbar: {
      report_create: "Create Report",
      campaign_create: "Create Campaign",
      report_edit: "Update Report",
      step: "The next step is {{value}}",
      advertiser: "Advertiser:",
    },
    suggestionCard: {
      addAll: "Add All",
    },
    table: {
      keywords: {
        keyword: "Keyword",
        matchType: "Match Type",
        suggested: "Suggested Bid",
        dynamic: "Auto",
        volume: "Search Volume",
        competition: "Competition",
        low: "Less than 1000",
        medium: "1K-5K",
        high: "5K-10K",
        highest: "10K+",
        search: "Add keywords...",
        suggestTitle: "Suggested Keywords",
        suggestText: "Recommended keywords for your products.",
        negativePlaceholder: "Add negative keywords...",
        exact: "Search terms that exactly match your keyword.",
        phrase:
          "Search terms that contain components of your keyword in the same order.",
        broad:
          "Search terms that contain components of your keyword in any order.",
        warning: "Minimum value must be {{value}}.",
      },
      products: {
        productSearch: "Search for product, sku or barcode.",
        storeSearch: "Search for store",
        loading: "Loading",
        title: "Please make a product selection.",
        text: "You can add the products you want to advertise by clicking the add button in the product list on the left.",
        productSelected: "Selected Products",
        storeSelected: "Selected Stores",
        remove: "Remove All",
        price: "Price",
        stock: "Stock",
        rating: "Rating",
        drawerTitle: "Bulk Product Selection",
        sku: "SKU or Barcode List",
        skuText:
          "Add the product SKU or Barcode you want to add, one per line, in the field below.",
        categories: "All Categories",
        bulk: "Bulk Select",
        all: "Show All",
        selected: "Show Selected Products",
        total: "There are <strong>{{value}} products</strong> in total.",
        variable: "Variable",
      },
      categories: {
        suggestTitle: "Recommended Categories",
        suggestText: "Recommended categories for your products",
      },
      result: {
        campaignName: "Campaign Name",
        campaign: "Campaign ID",
        campaignBidType: "Bid Type",
        campaignBudgetType: "Budget Type",
        advertiserName: "Advertiser Name",
        agencyName: "Agency Name",
        product: "Product",
        productName: "Product",
        categoryText: "Category",
        keyword: "Keyword ID",
        keywordText: "Keyword Text",
        keywordMatchType: "Match Type",
        productBrand: "Product Brand",
        day: "Day",
        week: "Week",
        month: "Month",
        year: "Year",
        hour: "Hour",
        sumImpression: "Impressions",
        sumViewableImpression: "Viewable Impression",
        sumClick: "Clicks",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "Sale",
        ACoS: "ACoS",
        sumDirectSale: "Direct Sale",
        sumIndirectSale: "Indirect Sale",
        sumDirectSaleAmount: "Direct Sale Amount",
        sumIndirectSaleAmount: "Indirect Sale Amount",
        sumSaleAmount: "Sale Amount",
        sumSpend: "Spend",
        campaignStartDate: "Start Date",
        campaignEndDate: "End Date",
        campaignStatus: "Status",
        campaignBudget: "Budget",
        advertiser: "Advertiser",
        agency: "Agency",
        weekOf: "Week of {{value}}",
        customerDeviceType: "Customer Device Type",
        saleProductName: "Indirect Sale Product",
        placementId: "Placement ID",
        customerType: "Customer Type",
        categoryName: "Category Pages",
        productRetailerTaxonomy: "Product Category",
        adPlacementCustomKey: "Ad Placement ID",
        adPlacementName: "Ad Placement Name",
        placementText: "Placement Text",
        in_search: "Search Results",
        in_category: "Category Pages",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Homepage",
        in_collection: "Collection",
        campaignAdType: "Campaign Type",
        campaignFixedBid: "Campaign Fixed Bid",
        keywordBid: "Keyword Bid",
        categoryBid: "Category Bid",
        advertiserResourceID: "Seller ID",
        productSKU: "Product ID",
        customerDeviceEnvironmentType: "Customer Device Environment Type",
        campaignDailyBudget: "Daily Budget",
        campaignTotalBudget: "Total Budget",
      },
    },
    states: {
      campaigns_not_started: "Campaign hasn't started yet.",
      campaigns_date_passed: "Campaign past its scheduled end date.",
      campaigns_status_paused: "You paused the campaign.",
      campaigns_out_of_daily_budget:
        "Your campaign’s daily budget has been exhausted.",
      campaigns_out_of_total_budget:
        "Your campaign has reached the total budget limit.",
      campaigns_products_stopped: "Products are not active.",
      campaigns_keywords_stopped: "Keywords are not active.",
      campaigns_categories_stopped: "Categories are not active.",
      campaigns_status_archived: "You archived the campaign.",
      campaigns_creatives_stopped: "There is no active creatives.",
      campaigns_insufficient_balance: "Your balance is insufficient.",
      campaigns_low_bid: "Bid is below the minimum bid.",
      campaigns_advertiser_paused: "Advertiser is not active.",
      products_status_paused: "You paused the product.",
      products_low_stock: "Status stopped due to stock constraints.",
      products_low_rating: "Status stopped due to rating constraints.",
      products_buybox_loser: "Status stopped due to buybox constraint.",
      products_product_paused: "Product is not active.",
      products_product_deleted: "Product is not active.",
      keywords_status_paused: "You paused the keyword status.",
      keywords_low_bid: "Bid is below the minimum bid.",
      categories_status_paused: "Category targeting paused.",
      categories_low_bid: "Bid is below the minimum bid.",
      creatives_paused: "You paused the creative status.",
      creatives_pending: "Creative is waiting for review.",
      creatives_rejected: "Creative is rejected.",
      creatives_note: "Rejection Reason:",
    },
    payment: {
      amount: "Amount to Charge:",
      title: "Payment Method",
      subtitle:
        "The advertising balance will be invoiced to you at a price including VAT.",
      wire_transfer: "Wire Transfer / EFT",
      beymen_milestone_payment: "Milestone Payment",
      koctas_milestone_payment: "Milestone Payment",
      modanisa_milestone_payment: "Milestone Payment",
      bank: "Bank Information",
      helpUpper:
        "After your payments are approved by wire transfer or EFT, will be transferred. Approval process can last",
      helpSecondary: "2-3 business days.",
      ref: "Reference No:",
      info: "Entering your reference number correctly in the description of the transaction is very important for tracking the transaction.",
      tax: "VAT:",
      verifyAmount: "Amount To Be Withdrawn From Your Account:",
      toters_milestone_payment: "Transfer from Wallet",
      product_placeholder: "Search for Store",
      craftgate: "Credit Card",
    },
    tour: {
      prev: "Back",
      next: "Next",
      done: "Finish the tour",
      title: "Welcome to the Ad Platform!",
      intro:
        "To learn how to use the platform, click “Start” and continue the product tour. You can skip the tour by clicking the exit button",
      menu: "Menu Bar",
      menuText: "You can use the Menu Bar in the left to switch between pages.",
      campaigns: "Campaigns",
      campaignsText:
        "You can manage your advertising campaigns from the “Campaigns” page. Here, you can view the amount of clicks and sales you get from the ads.",
      reports: "Reports",
      reportsText:
        "You can create reports picking different dimensions on the performance of your ads. With these reports, you can view how many times your ads were shown, in which search results or categories they were displayed at, or how many sales were made.",
      reportsTextSub:
        "While creating reports, you can choose predefined reports or choose customized reports that you will create yourself.",
      invoices: "Invoices",
      invoicesText:
        "In this section, you can view the balances you have uploaded in order to spend on your campaigns, the gift coupons earned, and all the ad spend.",
      academy: "Academy",
      academyText:
        "Here you can find the necessary information about the use of the platform.",
      widgets: "Statistics",
      widgetsText:
        "You can view the performance of the campaigns you create gradually. You can also examine your ad performance in detail on a daily basis by clicking “Show in Chart” button.",
      table: "Campaigns Table",
      tableText:
        "In this section, you can view the campaigns you have created and their performance. You can pause your campaigns at your wish. To view the details of your campaign, simply click the campaign name.",
      button: "Add New Campaign",
      buttonText:
        "Click the “Add New Campaign” button to start creating your first ad campaign.",
      modal: "Campaign Type",
      modalText: "Here you can see the types of ad campaigns you can choose.",
      type: "Sponsored Product",
      typeText:
        "You can create a Sponsored Product campaign where you can highlight your products on the top spots of search results or category pages.",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "Add New Campaign",
        placeholder: "Search in Campaigns",
        errorTitle: "No Advertiser Selected",
        errorText: "Please select an advertiser.",
        table: {
          standart: "Auto",
          max_acos: "Auto",
          fixed: "Fixed",
          daily: "Daily",
          flexible: "Daily",
          total: "Total",
          budgetLimit: "Limited to {{value}}",
          stopped: "You've stopped the campaign.",
          product: "Sponsored Product",
          display: "Sponsored Display",
          store: "Sponsored Store",
          default: "Default View",
          checkUp: "Checkup View",
          video: "Sponsored Video",
        },
      },
      campaignDetails: {
        subtext: "You can edit the campaign information.",
        products: "Products",
        on: "Active",
        keywords: "Keywords",
        negativeKeywords: "Negative Keywords",
        targeting: "Audiences",
        city: "City",
        age: "Age",
        gender: "Gender",
        platform: "Platform",
        customers: "Customers",
        placements: "Ad Placements",
        in_search: "Search Results",
        in_category: "Categories",
        placementPlaceholder: "Search in Ad Placement",
        date: "Start-End Date:",
        bid: "Bid:",
        maxAcos: "% Maximum ACoS",
        fixed: "Fixed",
        standart: "Auto",
        daily: "Daily",
        total: "Total",
        flexible: "Daily",
        none: "None",
        keywordsBtn: "Add Keyword",
        keywordsPlaceholder: "Search for Keywords",
        keywordsSub: "Add the keywords you want your ad to be displayed.",
        negative_keywords_btn: "Add Negative Keyword",
        negative_keywords_placeholder: "Search in Negative Keywords",
        negative_keywords_modal_subtitle:
          "Add the keywords you don't want your ad to be displayed.",
        negative_categories_btn: "Add Negative Category",
        negative_categories_placeholder: "Search in Negative Categories",
        negative_categories_modal_subtitle:
          "Add the categories you don't want your ad to be displayed.",
        productsBtn: "Add Products",
        productsPlaceholder: "Search Products",
        productsSub: "Select the products you want to advertise.",
        cities_placeholder: "Search Cities",
        age_ranges_placeholder: "Search Age Ranges",
        genders_placeholder: "Search Genders",
        platforms_placeholder: "Search Platforms",
        customers_placeholder: "Search Customer Types",
        new: "New Customers",
        present: "Existing Customers",
        keywordTitle: "Keyword Targeting",
        keywordText:
          "Keywords are automatically targeted for your ad. You can stop the keywords you don't want to target.",
        maxAcosLabel: "Max ACoS",
        fixedBidLabel: "Fixed Bid",
        productsError: "At least 1 product must be active.",
        keywordsError: "At least 1 keyword must be active.",
        categoriesError: "At least 1 category must be active.",
        categories: "Categories",
        categoriesBtn: "Add Category",
        categoriesPlaceholder: "Search in Categories",
        categoriesSub: "You can add categories to target.",
        negativeCategories: "Negative Categories",
        buyboxError: "Product is not buybox winner.",
        ratingError: "Product rating is below min rating.",
        stockError: "Product stock is below minimum stock.",
        notActiveError: "Product is not active.",
        creatives: "Creatives",
        creativesBtn: "Add Creatives",
        creativesPlaceholder: "Search Creatives",
        creativesSub: "Add the creatives you want to show in your ad.",
        creativesLinkTitle: "Redirect URL",
        creativesLinkText:
          "Users who click on your ad are directed to {{value}}.",
        winner: "Winner",
        loser: "Loser",
        creativesError: "At least 1 creative must be active.",
        pendingError: "Pending creatives can’t be active.",
        rejectedError: "Rejected creatives can’t be active.",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Home Page",
        in_collection: "Collection",
        allTimes: "All Times",
        scheduled: "Scheduled",
        dayParting:
          "You can choose the days and hours that your campaign will remain active.",
        video: "Videos",
        vastTag: "VAST Tag",
        videoFile: "Video File",
      },
      noMatch: {
        title: "Sorry, we can't find that page.",
        btn: "Return Home",
      },
      reports: {
        button: "Add New Report",
        placeholder: "Search in Reports",
        name: "Report Name",
        table: {
          type: "Report Type",
        },
        form: {
          title: "Report 1",
          goBack: "Go back",
          create: "Create Report",
        },
      },
      reportDetails: {
        result: "Report Result",
        static: "Fixed",
        scheduled: "Scheduled",
        ready: "Predefined Report",
        startDate: "Start Date",
        endDate: "End Date",
        frequency: "Frequency",
        firstDate: "First Post Date",
        month: "First Day of the Month",
        emails: "E-mails",
        export: "File Type",
        predefined: "Predefined Report Type",
        placeholderText:
          "Campaign Name / is any of / Micro USB Charging Cable Campaign",
        placeholderTextTwo: "Platform / is any of / Mobile, Desktop",
        product_one: "Micro USB 2.0A Fast Charging High Speed Data Transfer",
        product_two: "Micro USB 2A Charging and Data Cable 100 cm",
        product_three:
          "C107 Micro USB Fast Charging and Data Cable 1mt 3.0A 18W White",
        product_four: "SYX-C89 Samsung 2 Mah Micro USB Cable",
        product_five:
          "Baseus CAMKLF-BG1 Cafule Micro USB 2.4A Cable 1 mt - Black",
        campaignData: "Micro USB Charging Cable Campaign",
      },
      invoice: {
        balance: "Balance",
        coupon: "Coupon",
        lastPayment: "Last Loaded Balance",
        searchPlaceholder: "Search in Invoices",
        duplicateCouponsError:
          "Multiple coupon definition detected. Please wait.",
        multipleTopUpsError: "Multiple balance upload detected. Please wait.",
        limitError:
          "You are trying to add balance with a high amount. Please check the amount.",
        minAmountError: "Minimum payment amount should be {{value}}.",
        data: {
          typeBudget: "Balance Payment",
          typeAd: "Advertising Cost",
          typeBudgetExplanation: "Ad balance is loaded.",
          typeAdExplanation: "Advertising cost is spent.",
        },
        table: {
          type: "Transaction Type",
        },
        modal: {
          button: "Add Balance",
          title: "Add Balance",
          subtitle: "Enter the amount you want to transfer to your ad balance.",
          loadingAmount: "Amount",
          differentAmount: "Enter an amount.",
          label: "Amount",
        },
      },
      academy: {
        more: "Learn More",
        copy: "Copy Link",
        helpTitle: "Still can't find answers to your questions?",
        helpText:
          "For your questions, you can reach the support team by sending an e-mail.",
        contact: "Contact Us",
        highlights: "Highlights",
        read: "Read The Rest of The Article",
        description:
          "With the helpful articles of {{value}} Advertising Guide, you can learn everything you need to know about the platform and start publishing your ads easily.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext:
          "You can group advertisers by assigning them to agencies.",
        modalPlaceholder: "Search agency...",
        search: "Search in Advertisers",
        select_advertiser_subtitle:
          "Please choose which advertiser you want to create a campaign for.",
        userSubtext:
          "You can invite users who will use the advertiser account.",
        help: "Add the user's email address.",
        deleteSuccess: "The agency was successfully removed.",
        create: "Add New Advertiser",
        update: "Update Advertiser",
        type: "Advertiser Type:",
        custom: "Custom",
        customText:
          "It is the type of advertiser created with the products of the vendors that are set to be shareable within the platform.",
        name: "Advertiser Name",
        nameHelp: "The name of your advertiser that will be displayed by you.",
        agency: "Agency",
        agencyHelp: "It is the agency that the advertiser is affiliated with.",
        email: "E-mail",
        emailHelp:
          "Enter the e-mail address to which the invitation will be sent. The seller uses the e-mail address you entered when logging into the platform.",
        product: "Product Name",
        brand: "Brand",
        retailer_taxonomy: "Product Category",
        advertiser_id: "Advertiser ID",
        filters: "Filters:",
        filterWarning: "Please add at least one filter.",
        typeTitle: "Advertiser Type",
        typeSubtext:
          "Which of the following do you want to change the type of advertiser selected above?",
        default: "Default",
        defaultText:
          "It is used for seller accounts that sell directly under their own name within the platform.",
        shareable: "Shareable",
        shareableText:
          "It is used for seller accounts that sell on behalf of others within the platform and must be distributed to main sellers.",
        generalTitle: "General Information",
        generalSubtext: "General information about the advertiser",
        successText:
          "All products matching the brand of the seller you selected have been added.",
        advertiser: "Advertiser:",
        drawerFilterWarning: "The filters you add cannot be undone!",
        filterTitle: "Filter",
        filterText: "Select the filter you want to apply to advertisers.",
        keyLabel: "Select Filter",
        opLabel: "Select Condition",
        valueLabel: "Enter Value",
        categorySelectLabel: "Select Category",
        filterKeyError: "Please select a filter.",
        filterOpError: "Please select a condition.",
        filterValueError: "Please enter a value.",
        filterCategoryError: "Please select a category.",
      },
      advertiserSettings: {
        title: "Advertiser Settings",
        subtext: "Settings related to advertisers.",
        status: "Default Advertiser Status",
        statusText:
          "If the default status of advertisers added to the platform is closed, you must activate the status on the advertisers page so that they can log into the system.",
        welcome: "Welcome Coupon",
        welcomeText:
          "You can define a welcome coupon that the advertiser can use when they come to the platform for the first time.",
        warning:
          "If you enter “0”, the welcome coupon will not be defined for the advertisers.",
        label: "Coupon Balance",
        language: "Language",
        languageText:
          "It is the default interface language for advertisers. Advertisers can change the language selection through their own interface.",
        balance: "Balance Load Authorization",
        balanceText:
          "It is the authority of advertisers to transfer their progress payments to their ad balances via the interface.",
        open: "Open",
        closed: "Closed",
        error_title: "You must enter a value.",
        error_text:
          "If you don't want to define a coupon, you can enter “0” and click the save button.",
        error_text_input: "You must enter a value.",
      },
      agencies: {
        label: "Agency Name",
        subtext: "Enter an agency name",
        subtitle: "You can add agency to group advertisers",
        search: "Search in Agencies",
        edit: "Update the agency name",
      },
      auctionSettings: {
        title: "Auction Settings",
        subtext: "Auction related settings",
        type: "Auction Type",
        typeText:
          "Choose which auction type the advertisers' bids will be evaluated for. You can find detailed information about auction types here.",
        warning: "Changes will affect advertising spending on the platform.",
        firstOpt: "First Price",
        firstOptText: "Winning advertisers pay the bid placed.",
        secondOpt: "Second Price",
        secondOptText:
          "Winning advertisers pay 1 cent more than the second highest bid.",
        link: "Auction Types",
      },
      campaignSettings: {
        title: "Campaign Settings",
        subtext: "Settings related to campaign setup",
        transformation: "Conversion Range",
        transformationText:
          "You can specify the time period during which the user's purchasing activity will be attributed to the ad click. When you set the conversion range to 7 days, if the user clicks on the ad and buys the product within 7 days, the campaign balance is spent.",
        product: "Product Rating",
        productText:
          "You can add a minimum rating requirement for the products that advertisers will add to their campaigns, and customize the behavior for products that do not meet this condition.",
        minimum: "Minimum Product Rating",
        minimumText:
          "Enter the minimum product rating for the products to be added to the campaigns.",
        behaviour: "Set Behavior",
        behaviourText:
          "Enter the minimum product rating for the products to be added to the campaigns.",
        placeholder: "Select Behavior",
        stock: "Product Stock",
        stockText:
          "You can add a minimum stock requirement for the products that advertisers will add to their campaigns, and customize the behavior for products that do not meet this condition.",
        minimumStock: "Minimum Product Stock",
        minimumStockText:
          "Enter the minimum stock for the products to be added to the campaigns.",
        stockBehaviourText:
          "Determine the behavior that will occur when the number of stocks falls below the minimum stock you have determined.",
        alert: "Allow in Campaign, and Show Ads",
        block: "Don’t Allow in Campaign",
        targeting: "Demographic Targeting",
        targetingText:
          "In campaign setup, choose whether the demographic targeting step should be enabled or not. When enabled, an additional step is added to the campaign setup, allowing advertisers to set their targeting preferences.",
        all: "All Advertisers",
        none: "No Advertiser",
        selected: "Selected Advertisers",
        format: "Ad Formats",
        sponsored: "Sponsored Product",
        brand: "Sponsored Brand",
        ad: "Visual Advertising",
        rating_warn:
          "Your product rating is too low! Adding this product to your campaign will reduce performance.",
        rating_block:
          "Your product rating is too low! You cannot add this product to your campaign!",
        stock_warn:
          "Your stock is too low! Adding this product to your campaign will reduce performance.",
        stock_block:
          "Your stock is too low! You cannot add this product to your campaign!",
        buybox_warn:
          "The product is not a buy box winner. Adding this product to your campaign will reduce performance.",
        buybox_block:
          "The product is not a buy box winner. You cannot add this product to your campaign!",
        buyBoxTitle: "Buy Box Condition",
        buyBoxText:
          "You can add a Buy Box winner condition for the products that advertisers will add to their campaigns.",
        buyBoxSubtext:
          "Determine the behavior that will occur when the products to be added to the campaigns are not buy box winners.",
        noWorning: "None",
        warn: "The change will affect all active campaigns.",
        rangeError: "You must enter a value. The recommended value is 7 days.",
        rangeErrorInput: "Only a value between 1-14 days can be entered.",
        chooseBehaviour: "You must choose a behavior.",
        stockError: "You must enter a minimum stock.",
        allStockError: "You must enter a minimum stock and choose a behavior.",
        ratingError: "You must enter a minimum rating.",
        allRatingError:
          "You must enter a minimum rating and choose a behavior.",
        serverBlock: "Allow in Campaign, but Block Ads",
        advertisers: "Selected Advertisers",
        advertisersText:
          "Demographic targeting will only be available to advertisers you choose.",
        advertisersBtn: "Add Advertiser",
        advertisersSub: "Select advertisers who can use demographic targeting.",
        advertisersPlaceholder: "Choose Advertiser",
        advertisersLength: "{{value}} advertisers were selected.",
        advertisersTitle: "Advertisers",
        general: "General",
        bid: "Bid Restrictions",
        conversion: "Conversion",
        conversionSubtext:
          "Settings related to your conversion goals on the platform",
        biddingTypes: "Sponsored Product Available Bid Types",
        biddingTypesText:
          "In the campaign creation step, you can specify the types of bids that advertisers can use.",
        biddingTypesWarning: "At least one option must remain open.",
        auto: "Automatic Bid",
        autoText:
          "A bid type where bid is automatically determined. Expenditures are calculated using the price of the product sold and the target ACoS you set.",
        acos: "Target ACoS",
        acosText:
          "Bid is automatically calculated based on the rate of ACoS advertisers enter and the price of products sold.",
        fixed: "Fixed Bid",
        fixedText:
          "Bid is determined by the advertiser. Advertisers can set a campaign-wide bid per keyword and category.",
        bidWarning: "At least one bid type must be selected.",
        bidText: "Settings for platform-wide bidding rules",
        bidLabelTitle: "Overall Minimum CPC",
        bidLabelText:
          "The minimum bid amount per click that must be entered for all categories and keywords.",
        bidLabel: "Minimum CPC",
        cpmTitle: "Overall Minimum CPM",
        cpmText:
          "The minimum bid amount per impression that must be entered for all categories and keywords.",
        cpmLabel: "Minimum CPM",
        targetAcosTitle: "General Target ACoS",
        targetAcosText:
          "You can set the maximum percentage of ACoS to target by default in auctions.",
        targetAcosLabel: "Max. ACoS",
        categoryRelevanceTitle: "General Category Relevance",
        categoryRelevanceText:
          "It determines the level of relevance that should be between sponsored products and organic listed products in ad impressions.",
        categoryRelevanceLabel: "Category Relevance",
        bidTabWarning:
          "Changes made will affect advertising spend and impressions on the platform.",
        inputErrorZero: "You must enter a value greater than zero.",
        inputErrorAcos: "You must enter a value between 0-100.",
        inputErrorRelevance: "You must enter an integer greater than zero.",
        inputError: "You must enter a value.",
        productLink: "Product Rate Restriction",
        stockLink: "Product Stock Restriction",
        targetingLink: "Demographic Targeting Feature",
        bidLink: "Bid Types",
        minThreshold: "Minimum Sale Threshold",
        minThresholdText:
          "This is the minimum sale threshold for products to be included in the auction.",
        minThresholdLabel: "Minimum Sale Threshold",
        minThresholdError: "Please enter an integer.",
      },
      coupons: {
        add: "Define Coupon",
        title: "Advertiser Information",
        subtext:
          "You can define coupons for advertisers to use for campaign spending.",
        label: "Expiration Date",
        warning:
          "Advertisers must redeem the coupon by the entered date. If you don't enter an expiration date, the coupon will be added to the advertisers balance permanently and advertisers can use the coupon whenever they want.",
        gift_coupon: "Gift Coupon",
        welcome_coupon: "Welcome Coupon",
        gift_coupon_desc: "Gift coupon is defined.",
        welcome_coupon_desc: "Welcome coupon is defined.",
        approve: "Would you like to continue to define coupon?",
        note: "Coupon Description",
        noteHelp:
          "Include the reason for defining the coupon. Ex. Black Friday Coupon",
      },
      creatives: {
        all: "All",
        accepteds: "Accepted",
        rejecteds: "Rejected",
        pendings: "Pending",
        pending: "Pending",
        accepted: "Accepted",
        rejected: "Rejected",
        adModalTitle: "Ad Preview",
        creativeModalTitle: "Creative Preview",
        creative: "Creative",
        name: "Creative Name",
        search: "Search in Creatives",
        videoModalTitle: "Video Preview",
        rejectSubtitle:
          "You can enter the reason for rejecting the creative or leave it blank and continue.",
        note: "Enter the reason for rejecting the creative.",
      },
      generalInfo: {
        title: "General Information",
        subtext: "General information about the marketplace",
        market: "Marketplace Name",
        marketText:
          "The name of the store that appears on the interface. You need to contact your account manager to change this information.",
        url: "URL",
        urlText:
          "It is the link of the store to be used in the interface and for routing. You need to contact your account manager to change this information.",
        country: "Country",
        countryText: "The country to be used for city targeting.",
        zone: "Time Zone",
        zoneText:
          "It is the default time zone to be used in interfaces. You need to contact your account manager to change this information.",
        currency: "Currency",
        currencyText:
          "Currency to be used in interfaces. This information cannot be changed.",
        zoneIstanbul: "Europe/Istanbul",
        email: "Support Email Address",
        emailText: "It is the e-mail address that your sellers will contact.",
        emailLabel: "E-mail",
        emailError: "You must enter an e-mail.",
        seller: "Seller Panel",
        sellerText:
          "It is the address that will allow your sellers to be directed directly to your panel through the interface.",
        sellerLabel: "Seller Panel",
        marketError: "You need to enter the marketplace name.",
        urlError: "You must enter an URL.",
      },
      inspect: {
        campaign: "Campaign:",
        advertiser: "Advertiser:",
        url: "URL:",
        size: "Size:",
        status: "Status:",
        keywords: "Keywords",
        categories: "Categories",
        vastTag: "VAST Tag:",
      },
      integrationInfo: {
        title: "Integration Information",
        subtext: "Integration information required to perform data transfer",
        api: "API Security Code",
        apiText:
          "It is the required security code to access the platform via API.",
        ftp: "FTP Security Certificate",
        ftpText: "It is the required certificate to access the FTP server.",
        download: "Download",
        copy: "Click to copy.",
        copied: "Copied",
        auto: "Auto Impression",
        autoText:
          "It is a setting that allows all impressions to be recorded automatically without any event integration. When the setting is turned on, a view metric is recorded for each product, assuming that all products returned in the ad response are shown on your site. When the setting is off, an event request should be sent for each display in accordance with the desired format.",
        link: "Impression Event",
        apiLink: "API Endpoints",
      },
      transactions: {
        subtext:
          "You can define balances for advertisers to use in their campaign spending.",
        warning: "You can add the invoice number.",
        balance_updated: "Balance Payment",
        gift_coupon: "Gift Coupon",
        welcome_coupon: "Welcome Coupon",
        refund: "Refund",
        spend: "Advertising Cost",
        ad_balance_loaded_desc: "Ad balance loaded.",
        spend_desc: "Advertising cost spent.",
        gift_coupon_desc: "Gift coupon earned.",
        welcome_coupon_desc: "Welcome coupon earned.",
        ad_balance_refunded_desc: "Ad balance refunded.",
        credit_card_desc: "Balance updated via credit card.",
        detailsSubtext: "You can view details about your ad spend.",
        spendTab: "Advertising Costs",
        budgetTab: "Budget Resources",
        advertiser_budget: "Balance",
        approve: "Would you like to continue to add balance?",
        advertiser: "Advertiser:",
        balance: "Balance to be loaded:",
        update: "You can add or edit the invoice number.",
        title: "Invoice Information",
        payment: "Payment",
        success: "Success",
        waiting: "Waiting",
        failure: "Failure",
        invoiceWarning: "You can enter the invoice number up to 30 characters.",
        note: "Balance Upload Note",
        noteHelp:
          "Include the reason for uploading this balance. Ex. August Balance",
      },
      users: {
        label: "E-Mail",
        subtext: "A confirmation mail will be sent for the invitation.",
        invite:
          "Enter the e-mail address to which the invitation will be sent.",
        warn: "You cannot stop your own account.",
        error: "You have added an existing email address.",
        search: "Search in Users",
        select: "Select the type of user you want to add.",
        advertiser: "Advertiser",
        advertiserText:
          "Only the advertiser account you choose can access, it is the person who will run the advertising activities.",
        marketplace: "Admin",
        marketplaceText:
          "It has all the competencies in the advertising platform, can grant or manage access to users.",
        limited: "Limited Access",
        full: "Full Access",
      },
      invited: {
        error:
          "An invite can be sent to the same email for the same account at most every 10 minutes.",
        success: "The user has been reinvited.",
        search: "Search in Invited Users",
        title: "Invitation Details",
        info: "General Information",
        mail: "Email:",
        advertiser: "Advertiser:",
        urlTitle: "Invitation Link",
        link: "How to add an advertiser user?",
        single: "Single User",
        multiple: "Bulk User",
        advertiserText: "Select advertiser.",
        draggerTitle:
          "Select a <strong>.CSV</strong> file to upload Bulk Email",
        draggerText: "or drag and drop here.",
        csvError: "An error occurred while uploading the file.",
        csvInfo:
          "You can bulk invite users to different advertiser accounts using a CSV file. You can access information about the format of the CSV file using the link below.",
        restriction: "Limited Access",
        restrictionText:
          "When you turn on this option, the user is given only limited access.",
        edit: "Edit",
        role: "Role:",
        MARKETPLACE: "Admin",
        MARKETPLACE_REPORTER: "Admin Reporter",
        ADVERTISER: "Advertiser",
        ADVERTISER_REPORTER: "Advertiser Reporter",
        archived: "Cannot send invitations to archived advertisers.",
      },
      sync: {
        file: "File",
        api: "API",
        feed: "Feed",
        products_created: "Products are created.",
        products_updated: "Products are updated.",
        products_deactivated: "Products are deactivated.",
        products_activated: "Products are activated.",
        missing_advertisers: "Advertisers are missing.",
        advertisers_created: "Advertisers are created.",
        advertisers_updated: "Advertisers are updated.",
        advertisers_deactivated: "Advertisers are deactivated.",
        advertisers_activated: "Advertisers are activated.",
        categories_created: "Categories are created.",
      },
      placement: {
        add: "Add New Ad Placement",
        display: "Display",
        product: "Sponsored Product",
        update: "Update Ad Placement",
        settings: "General Settings",
        settingsText: "General settings of your ad placement",
        name: "Ad Placement Name",
        nameText: "This name is visible only to you.",
        format: "Ad Format",
        formatText: "The format in which your ad placement will be displayed",
        sponsoredText: "Product display in related keywords and categories",
        displayText: "Visual ad display in related keywords and categories",
        suggestedTitle: "Recommended Sizes",
        suggestedText:
          "The actual size of your ad placement. This size will be displayed by advertisers.",
        suggestedError: "You can add up to 1 size.",
        acceptedTitle: "Accepted Sizes",
        acceptedText:
          "Other ad sizes that can appear within your ad placement. Dimensions will be displayed by advertisers.",
        acceptedError: "Please, select an ad size.",
        formatError: "Please, choose a format.",
        cross_sell: "Cross-Sell",
        crossText:
          "Displaying advertisements on the relevant product detail pages and displaying the listed products as advertisements are prevented.",
        max_ads: "Maximum Number of Ads",
        maxText: "The maximum number of ads the placement can have.",
        crossTitle: "Ad Blocking Behavior",
        crossSub:
          "The actual size of your ad placement. This size will be displayed by advertisers.",
        crossPlaceholder: "Choose a type.",
        crossProduct: "Block Listed Product",
        crossBlock: "Don’t Block",
        crossError: "Choose a behavior.",
        targeting: "Targeting",
        targetingText:
          "Select the placement group to target your ad placement.",
        typeError: "Please select a targeting option.",
        targetingPlaceholder: "Target Placement",
        search: "Search Results",
        category: "Category Pages",
        homepage: "Homepage",
        detail: "Product Detail Pages",
        collection: "Collection",
        video: "Sponsored Video",
        videoText:
          "Sponsored video display in relevant keywords and categories",
      },
      restrictions: {
        category: "Category",
        keyword: "Keyword",
        categoryTitle: "Category Based Bid & ACoS",
        categoryText:
          "You can enter a minimum bid and target ACoS that will be valid only in the categories you specify, and customize the category relevance. The settings you make in this field take priority over the general settings.",
        keywordTitle: "Keyword Blacklist",
        keywordText:
          "You can restrict the display of ads on the keywords you specify. In the search results restricted with the Exclusive type, only products containing this word are advertised. No ads are displayed in the search results restricted by the block type.",
        placeholder: "Search for keywords",
        add: "Add New Keyword",
        info: "General Information",
        label: "Keyword",
        help: "The keyword you want to restrict the search results for",
        type: "Restriction Type",
        typeText:
          "Type of restriction to be applied to search results with your keyword",
        exclusive: "Exclusive",
        exclusiveText:
          "In the search results, only the products with your keyword are advertised.",
        block: "Block",
        blockText: "All ads are blocked in search results.",
      },
    },
    auth: {
      title: "{{value}} Ad Platform",
      accounts: {
        title: "Select Account",
        text: "Select the account you want to log into.",
        logout: "Log Out",
        noAccount: "Account  Not  Active!",
        noAccountText:
          "It looks like you're not connected to any active accounts. For assistance, please contact your account manager via <strong>{{value}}</strong>",
        noAccountTextNoEmail:
          "It looks like you're not connected to any active accounts. For assistance, please contact your account manager.",
        contact: "Contact",
        login: "Return to Home Page",
      },
      login: {
        title: "Login",
        text: "Welcome again! Please enter your information.",
        remember: "Remember Me",
        forget: "Forgot My Password?",
        button: "Log In",
        mail: "Email",
        password: "Password",
        mailErr: "Please enter your email.",
        passwordErr: "Please enter your password.",
        incorrectPassword: "Your password is incorrect!",
        incorrectPasswordText: "Please try again.",
        notActive: "Your account has been suspended.",
        notActiveText: "Please contact the support team.",
        multipleAccountErr: "An error occurred while creating a new account.",
        notHaveAnAccount: "Don't have an existing account?",
        signup: "Sign Up",
      },
      onboarding: {
        help: "Help",
        login: "Log in",
        to: "to",
        retail: "get a retail media",
        infrastructure:
          "infrastructure set up in <strong>just a few minutes.</strong>",
        linkedInButton: "Sign up with Linkedin",
        linkedInTextAccept:
          "By signing up, you agree to our <1>Terms and Conditions</1>",
        firstStepText:
          "You can add retail media capability to your platform within minutes, without the need for technical development or months-long integrations. In just a few minutes, your vendors will be able to advertise on your website.",
        changeAccount: "Change Account",
        createTitle: "Create Your Dashboard",
        createSubtitle: "Enter platform information and complete the setup!",
        websiteLabel: "Company Website URL",
        websiteHelp: "Enter the URL of company website.",
        passwordLabel: "Password",
        passwordHelp: "Password needs to be at least 8 characters.",
        typeLabel: "Sample Data Type",
        typeHelp: "Select the type of sample data to be added to the platform.",
        clothing: "Clothing",
        tech: "Tech",
        grocery: "Grocery",
        continue: "Continue",
        thirdStepText:
          "Set up your campaign-specific targeting, bidding, and budget settings with an easy setup and launch your campaign!",
        campaignTitle: "Create Your First Campaign",
        campaignText:
          "Create your first retail media campaign by uploading an image. If you don’t have one, you can use a sample image.",
        autoTitle: "Auto Placement",
        autoText:
          "Ads opens automatically at the top on your website, no coding required.",
        autoTag: "Suitable for non-technical users.",
        customTitle: "Custom Placement",
        customText: "Choose ad placement and size. Requires basic coding.",
        customTag: "Suitable for technical users.",
        sampleBtn: "Use Sample Image",
        uploadBtn: "Use Custom Image",
        sampleText:
          "The following image will be used as an example in your ad preference.",
        uploadText: "This image only size for 970x50px is allowed!",
        uploadInnerTitle: "Upload a <strong>.JPG, .PNG</strong> file!",
        uploadInnerText: "or drop a file here.",
        errorTitle: "There's an error in the image!",
        errorText: "Please upload an image that fits the specified dimensions!",
        errorBtn: "Upload a New Image",
        errorWarning: "The image you uploaded must be size 970x50px!",
        create: "Create Campaign",
        fourthStepText:
          "Effortlessly display ads on your website by embedding the generated script into your site's code!",
        embedCodeTitle: "Embed code into your website",
        embedCodeText:
          "Integrate the code below into your website’s HTML or through a tag manager to run your ad.",
        pending: "Waiting for Traffic",
        live: "Live",
        activityTitle: "Script Activity:",
        connectionText:
          "If the connection is not established automatically, click the button!",
        checkConnection: "Check Connection",
        finishOnboarding: "Go to Dashboard",
        redirectURL: "Your Ad’s Redirect URL",
        redirectHelp:
          "Please provide the URL where users will be redirected when they click on your ad.",
        sampleBanner: "Use Sample Image",
        sampleBannerText: "Your ad will appear on your website like this.",
        account: "Already have an account?",
        link: "Login",
        uploading: "Uploading...",
        custom: "My Own Provider",
        gtm: "Google Tag Manager",
      },
      signUp: {
        title: "Sign Up",
        text: "Welcome, {{value}}",
        password: "Password",
        passwordErr: "Please enter your password.",
        warning:
          "Enter a password of at least 8 characters including numbers, punctuation marks, uppercase and lowercase letters.",
        already: "Do you have an existing account?",
        successBtn: "Log In",
        success: "Registration Successful!",
        successText:
          "Your registration with {{value}} mail has been successfully completed. Click the login button to continue to the panel.",
        help: "How Can We Help?",
        err: "Please enter a valid password.",
        userExistErr:
          "This user already exists. Please log in to see the accounts you can access.",
        privacyErr:
          "To complete your signup, please accept all required agreements.",
        error: "Invitation not found.",
        errorText:
          "You need an invitation to register. Please contact your account manager via {{value}}.",
        errorTextNoEmail:
          "You need an invitation to register. Please contact your account manager.",
        errorBtn: "Return to Home Page",
      },
      password: {
        title: "Did you forget your password?",
        text: "We'll send you a link so you can reset your password. Please enter the email address you use on the platform.",
        mail: "E-mail",
        mailInputErr: "Please enter your e-mail address.",
        reset: "Reset Password",
        return: "Go to Login Page",
        error:
          "Please enter the e-mail address whose password you want to reset.",
        check: "Check Your Email",
        message: "We sent a password reset link to {{value}}",
      },
      newPassword: {
        title: "Set New Password",
        text: "Your new password must be different from the old passwords you used.",
        help: "Enter a password of at least 8 characters including numbers, punctuation marks, uppercase and lowercase letters.",
        password: "Password",
        passwordInputErr: "Please enter your password.",
        again: "Repeat Password",
        check: "Password Reset Successful",
        message: "You can log in with the new password you have set.",
        errTitle: "Please enter a valid password.",
      },
      welcome: {
        title: "Welcome!",
        loginText:
          "If you have an account, you can enter your information by clicking the login button.",
        loginBtn: "Login",
        signupText:
          "If you do not have an account yet, you can click the button below to register, set your password and then log in to your account.",
        signupBtn: "Sign Up",
      },
    },
  },
  common: {
    save: "Save",
    selectAll: "Select All",
    search: "Search",
    edit: "Edit",
    export: "Export",
    remove: "Remove",
    add: "Add",
    column: "Column",
    archive: "Archive",
    removeArchive: "Unarchive",
    delete: "Delete",
    filter: "Filter",
    continue: "Continue",
    cancel: "Cancel",
    cancellation: "Cancel",
    okay: "OK",
    create: "Create",
    goBack: "Go Back",
    listed: "Listed",
    archived: "Archived",
    invited: "Invitees",
    exact: "Exact Match",
    phrase: "Phrase Match",
    broad: "Broad Match",
    turkey: "Turkey",
    abd: "USA",
    try: "Turkish Liras",
    usd: "US Dollars",
    iqd: "The Iraqi Dinar",
    twd: "New Taiwan Dollar",
    hkd: "Hong Kong Dollar",
    krw: "South Korean Won",
    jpy: "Japanese Yen",
    zone: "Europe/İstanbul",
    clear: "Clear",
    completed: "Completed",
    notCompleted: "Not Completed",
    now: "Now",
    checkAll: "Select All",
    keyword: "Keyword",
    negative: "Negative Keyword",
    tr: "Turkish",
    en: "English",
    day: "Day",
    invite: "Invite",
    accept: "Accept",
    reject: "Reject",
    pageTotal: "Number of rows to show:",
    addUser: "Add User",
    reinvite: "Invite Again",
    advertiser: "Advertiser",
    admin: "Admin",
    new_customer: "New Customers",
    existing_customer: "Existing Customers",
    category_buyer: "Category Buyers",
    invite_url: "See Invite Link",
    copy: "Create a Copy",
    vastTag: "Get VAST Tag",
    next: "Next",
    watch: "Watch",
    or: "or",
    exit: "Exit",
    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },
    table: {
      name: "Name",
      status: "Active",
      state: "Status",
      agency_name: "Agency",
      type: "Advertiser Type",
      resource_id: "Seller ID",
      last_login: "Last Login Date",
      balance: "Balance",
      sum_coupons_amount: "Coupon",
      total_balance: "Total Balance",
      impressions: "Impression",
      viewable_impressions: "Viewable Impression",
      clicks: "Click",
      CTR: "CTR",
      spend: "Ad Spend",
      sale_amount: "Sale Amount",
      direct_sale_amount: "Direct Sale Amount",
      indirect_sale_amount: "Indirect Sale Amount",
      sale: "Sale",
      direct_sale: "Direct Sale",
      indirect_sale: "Indirect Sale",
      ACoS: "ACoS",
      CVR: "CVR",
      campaign_name: "Name",
      advertiser_name: "Advertiser",
      ad_type: "Ad Type",
      start_date: "Start Date",
      end_date: "End Date",
      budget_type: "Budget",
      bid_type: "Bid Type",
      fixed_bid: "Bid",
      custom_key: "ID",
      ad_format: "Ad Format",
      max_ads: "Max. Number of Ads",
      recommended_size: "Recommended Size",
      sizes: "Sizes",
      image_url: "Creative",
      policy_status: "Status",
      transaction_date: "Date",
      invoice_number: "Invoice No",
      transaction_type: "Transaction Type",
      description: "Description",
      created_by: "Creator",
      amount: "Amount",
      spend_details: "Detail",
      note: "Note",
      expire_date: "Expiration Date",
      account_user_status: "Active",
      user_email: "E-Mail",
      account_user_role: "Auth",
      user_last_login: "Last Login Date",
      account_user_created_at: "Creation Date",
      email: "E-Mail",
      account_role: "Auth",
      created_at: "Invitation Date",
      report_name: "Name",
      report_start_date: "Start Date",
      report_end_date: "End Date",
      report_type: "Report Type",
      report_delivery_frequency: "Frequency",
      report_created_at: "Creation Date",
      text: "Name",
      minimum_bid: "Min. CPC",
      minimum_cpm: "Min. CPM",
      relevance: "Relevance",
      restriction_type: "Restriction Type",
      row_count: "Row Count",
      message: "Operation Message",
      sync_type: "Sync. Type",
      file_name: "File Name",
      product: "Product",
      sku: "SKU",
      buybox: "Buybox",
      city: "City",
      age_range: "Age",
      gender: "Gender",
      platform: "Platform",
      customer: "Customers",
      category: "Category",
      keyword: "Keyword",
      match_type: "Match Type",
      bid: "Bid",
      category_volume: "Impression Volume",
      active: "Active",
      paused: "Paused",
      invited: "Invited",
      uninvited: "Uninvited",
      accepted: "Accepted",
      archived: "Archived",
      on: "Active",
      stopped: "Paused",
      open: "Open",
      closed: "Closed",
      coupon: "Coupon",
      sync_log_created_at: "Date",
      category_text: "Category",
      keyword_text: "Keyword",
      creative_name: "Name",
      invoice: "Invoice No",
      acos: "ACoS",
      sum_click: "Clicks",
      sum_spend: "Spend",
      sum_impression: "Impressions",
      sum_viewable_impression: "Viewable Impression",
      sum_sale: "Sale",
      sum_direct_sale: "Direct Sale",
      sum_indirect_sale: "Indirect Sale",
      sum_direct_sale_amount: "Direct Sale Amount",
      sum_indirect_sale_amount: "Indirect Sale Amount",
      sum_sale_amount: "Sale Amount",
      invite_status: "Invite Status",
    },
    equality: {
      contains: "Contains",
      not_contains: "Does Not Contains",
      equal: "Equal",
      not_equal: "Not Equal",
      less_than: "Less Than",
      grater_than: "Greater Than",
      greater_or: "Greater Or",
      less_or: "Less Or",
      any_of: "Any Of It",
      none_of: "None Of It",
    },
    filters: {
      campaignName: "Name",
      campaignStartDate: "Start Date",
      campaignEndDate: "End Date",
      campaignStatus: "Status",
      campaignBudget: "Budget",
      campaignBudgetType: "Budget Type",
      campaignBidType: "Bid Type",
      campaignMaxAcos: "Maximum ACoS",
      campaignFixedBid: "Fixed Bid",
      sumSale: "Sale",
      sumSpend: "Spent",
      ACoS: "ACoS",
      product: "Product",
      productCampaignStatus: "Status",
      sumClick: "Click",
      CVR: "CVR",
      keywordCampaignStatus: "Status",
      keywordText: "Keyword",
      keywordMatchType: "Match Type",
      negativeKeywordCampaignStatus: "Status",
      negativeKeywordText: "Negative Keyword",
      negativeKeywordMatchType: "Match Type",
      cityName: "City",
      cityCampaignStatus: "Status",
      ageRangeText: "Age",
      ageRangeCampaignStatus: "Status",
      genderText: "Gender",
      genderCampaignStatus: "Status",
      platformText: "Platform",
      platformCampaignStatus: "Status",
      customerTargetingText: "Customer",
      customerTargetingCampaignStatus: "Status",
      placementText: "Ad Placement",
      placementCampaignStatus: "Status",
      reportName: "Report Name",
      reportStartDate: "Start Date",
      reportEndDate: "End Date",
      reportType: "Report Type",
      reportDeliveryFrequency: "Frequency",
      reportCreatedAt: "Creation Date",
      agencyName: "Agency Name",
      advertiserName: "Advertiser",
      advertiserStatus: "Advertiser Status",
      sumSaleAmount: "Sale Amount",
      sumDirectSale: "Direct Sale",
      sumIndirectSale: "Indirect Sale",
      sumDirectSaleAmount: "Direct Sale Amount",
      sumIndirectSaleAmount: "Indirect Sale Amount",
      sumImpression: "Impressions",
      productBrand: "Product Brand",
      CTR: "CTR",
      keyword: "Keyword",
      category: "Category",
      advertiserId: "Advertiser ID",
      agencyId: "Agency ID",
      name: "Product Name",
      brand: "Brand",
      retailer_taxonomy: "Product Category",
      advertiser_id: "Advertiser ID",
    },
    equalityValues: {
      ct: "Contains",
      ni: "Does Not Contain",
      eq: "Equal",
      ne: "Not Equal",
      in: "Any Of It",
      notin: "None Of It",
      lt: "Less Than",
      gt: "Greater Than",
      ge: "Greater Or",
      le: "Less Or",
    },
    values: {
      ACTIVE: "Active",
      PAUSED: "Paused",
      DAILY: "Daily",
      TOTAL: "Total",
      STANDART: "Auto",
      FIXED: "Fixed",
      MAX_ACOS: "Max ACoS",
      WEEKLY: "Weekly",
      MONTHLY: "Monthly",
      EXACT: "Exact",
      PHRASE: "Phrase",
      BROAD: "Broad",
    },
    month: {
      may: "May",
      june: "Jun",
      september: "September",
      october: "October",
      december: "December",
    },
  },
};

export default en;
