import "moment/locale/tr";
import "moment/locale/en-gb";

import localeEN from "antd/es/date-picker/locale/en_US";
import localeTR from "antd/es/date-picker/locale/tr_TR";
import ar from "antd/lib/locale/ar_EG";
import en from "antd/lib/locale/en_US";
import jp from "antd/lib/locale/ja_JP";
import kr from "antd/lib/locale/ko_KR";
import tr from "antd/lib/locale/tr_TR";
import ch from "antd/lib/locale/zh_CN";
import { TFunction } from "react-i18next";

import RetailNotification from "../components/Notification";
import RetailTableTag from "../components/Tag/RetailTableTag";
import RetailText from "../components/Typography/RetailText";
import { DisplayNamesType, Schedule } from "./types";
import { currency, noLanguageSelected } from "./defaults";

//Render tag according to data value coming from table row data.
export const renderTag = (value: "ACTIVE" | "PAUSED", t: TFunction) => {
  return (
    <RetailTableTag mode={value === "ACTIVE" ? "green" : "unfilledYellow"}>
      <RetailText weight="bold" family="poppins" size="xxxs">
        {value === "ACTIVE" ? t("common.table.on") : t("common.table.stopped")}
      </RetailText>
    </RetailTableTag>
  );
};

//Validate if input keypress is number, delete or backspace. Otherwise prevent key event.
export const validateNumber = (e: any, limited = false) => {
  if (e.which === 8 || e.which === 46 || e.which === 190) return;
  if (limited && e.target.value.length > 4) e.preventDefault();
  if (isNaN(parseInt(e.key))) {
    e.preventDefault();
  }
};

// Truncate text to given length
export const truncate = (text: string) => {
  if (!text.includes(">") || text.length < 25) return text;
  const newText = text.split(">");
  return `... > ${newText[newText.length - 1]}`;
};

export const datePickerLocale = (lang: string) => {
  if (lang === "en") return localeEN;
  else return localeTR;
};

//Divide given string between given character
export const getSubstring = (str: string, char1: string, char2: string) => {
  return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
};

export const convertKeywordVolume = (volume: string, t: TFunction) => {
  if (volume) {
    switch (volume) {
      case "0-1K":
        return t("components.table.keywords.low");
      case "1K-5K":
        return t("components.table.keywords.medium");
      case "5K-10K":
        return t("components.table.keywords.high");
      case "10K+":
        return t("components.table.keywords.highest");
      default:
        break;
    }
  } else return "-";
};

export const numFormatter = (value: number | string, spendColumn = false) => {
  const langForFormatter =
    localStorage.getItem("retail_lang") || noLanguageSelected;
  // Get decimal round value from marketplace config
  const decimalRound =
    (window as any).marketplaceConfig?.decimal_round || false;

  const formatter = () => {
    switch (langForFormatter) {
      case "tr":
        return "tr-TR";
      default:
        return "en-US";
    }
  };

  let num = (typeof value === "string" ? parseFloat(value) : value) || 0;

  const shouldBeRounded = decimalRound && spendColumn;

  if (shouldBeRounded) {
    num = Math.round(num);
  }

  return num.toLocaleString(formatter(), {
    maximumFractionDigits: shouldBeRounded ? 0 : 2,
  });
};

export const localCatalogValues = (
  type: DisplayNamesType,
  countryCode: string,
  lang: string
) => {
  // Create an Intl.DisplayNames object for the user's language
  const regionNamesInFrench = new Intl.DisplayNames(lang, { type });
  // Get the localized name for the given country code
  const localizedCountryName = regionNamesInFrench.of(countryCode);
  // Return the localized country name
  return localizedCountryName;
};

export const controlSelected = (id: number, arr: any[], iterableArr: any[]) =>
  arr
    .map((keyword) => keyword.id)
    .filter((keyword_id) =>
      iterableArr.map((key: any) => keyword_id === key.id)
    )
    .includes(id);

export const prefix = () => {
  switch (currency) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "IQD":
      return "د. ع";
    case "TWD":
      return "NT$";
    case "HKD":
      return "HK$";
    case "KRW":
      return "₩";
    case "JPY":
      return "¥";
    default:
      return "₺";
  }
};

interface ISelectCurrency {
  amount: string | number;
}

export const SelectCurrency = ({ amount }: ISelectCurrency) => (
  <>
    {prefix()}
    {amount}
  </>
);

export const budgetErrorMessage = (value: number, t: TFunction) => {
  return Promise.reject(
    new Error(
      t("components.campaignForm.firstStep.budgetError", {
        value: `${value}${prefix()}`,
      })
    )
  );
};

export const settingsSuccessNotification = (t: TFunction) => {
  return RetailNotification.showNotification(
    "success",
    "",
    t("components.notification.settings_success")
  );
};

export const renderSettingsLinks = (
  link: string,
  el: {
    className: string;
    text: string;
  }
) => {
  if (link === "") return null;
  else
    return (
      <a href={link} className={el.className} target="_blank" rel="noreferrer">
        {el.text}
      </a>
    );
};

export const days = (t: TFunction) => [
  t("common.days.monday"),
  t("common.days.tuesday"),
  t("common.days.wednesday"),
  t("common.days.thursday"),
  t("common.days.friday"),
  t("common.days.saturday"),
  t("common.days.sunday"),
];

export const daysMap = (t: TFunction): { [key: string]: string } => ({
  [t("common.days.monday")]: "monday",
  [t("common.days.tuesday")]: "tuesday",
  [t("common.days.wednesday")]: "wednesday",
  [t("common.days.thursday")]: "thursday",
  [t("common.days.friday")]: "friday",
  [t("common.days.saturday")]: "saturday",
  [t("common.days.sunday")]: "sunday",
});

export const generateDayPartingObject = (
  picker: string,
  schedule: Schedule,
  t: TFunction
) => {
  if (picker === "ALL_TIMES") {
    return {
      dayparting: {
        all_week: true,
        all_day: null,
        schedule: null,
      },
    };
  }

  const allDay: { [key: string]: boolean } = {};
  const scheduleObj: { [key: string]: boolean[] } = {};

  days(t).forEach((day, index) => {
    const dayKey = daysMap(t)[day];
    if (schedule[index].every((hour) => hour)) {
      allDay[dayKey] = true;
    } else if (schedule[index].some((hour) => hour)) {
      scheduleObj[dayKey] = schedule[index];
    }
  });

  return {
    dayparting: {
      all_week: false,
      all_day: Object.keys(allDay).length > 0 ? allDay : null,
      schedule: Object.keys(scheduleObj).length > 0 ? scheduleObj : null,
    },
  };
};

export const getLanguageCode = (lang: string) => {
  switch (lang) {
    case "en":
      return "en-US";
    case "tr":
      return "tr";
    case "ar":
      return "ar";
    case "jp":
      return "ja";
    case "ch":
      return "zh";
    case "kr":
      return "ko";
    default:
      return "en-US";
  }
};

export const localeLanguages = (lang: string) => {
  switch (lang) {
    case "en":
      return en;
    case "tr":
      return tr;
    case "ar":
      return ar;
    case "jp":
      return jp;
    case "ch":
      return ch;
    case "kr":
      return kr;

    default:
      return en;
  }
};

export const cleanOnboardingData = () => {
  const localStorageKeysForCleaning = [
    "linkedin",
    "marketplace_id",
    "visited_steps",
    "onboarding_status",
  ];
  localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
};

const cleanAuthData = () => {
  const localStorageKeysForCleaning = [
    "auth_token",
    "auth_email",
    "auth_info",
    "account_index",
    "advertiser_id",
    "_grecaptcha",
  ];
  localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
};

export const cleanAllData = () => {
  cleanAuthData();
  cleanOnboardingData();
};
